.mui-styled-input {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding: 4px 0;
  background: transparent;
}

.mui-styled-input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}

.mui-styled-input:focus-within {
  border-bottom: 2px solid #1976d2;
  padding-bottom: 3px; 
}

.mui-styled-input input {
  font-size: 1rem;
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  padding: 4px 0;
} 